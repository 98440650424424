import {
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts } from
"@remix-run/react";

import styles from "~/styles.css";

import { LinksFunction, MetaFunction } from "@remix-run/node";

export const meta: MetaFunction = () => [
{
  charset: "utf-8"
},
{
  title: "Jaunt App"
},
{
  name: "viewport",
  content: "width=device-width,initial-scale=1"
}];


export const links: LinksFunction = () => [
{ rel: "stylesheet", href: styles },
{
  rel: "apple-touch-icon",
  sizes: "180x180",
  href: "/apple-touch-icon.png"
},

{
  rel: "icon",
  type: "image/png",
  sizes: "32x32",
  href: "/favicon-32x32.png"
},

{
  rel: "icon",
  type: "image/png",
  sizes: "16x16",
  href: "/favicon-16x16.png"
},
{
  rel: "manifest",
  href: "/site.webmanifest"
},
{
  rel: "mak-icon",
  href: "/safari-pinned-tab.svg",
  color: "#5bbad5"
}];


export default function App() {
  return (
    <html>
      <head>
        <Meta />
        <Links />
      </head>
      <body>
        <nav>
          <Link key="projects" to="projects">
            Projects
          </Link>
          <Link key="time-logs" to="time-logs">
            History
          </Link>
          <Link key="settings" to="settings">
            Settings
          </Link>
        </nav>
        <Outlet />
        <Scripts />
        <LiveReload />
      </body>
    </html>);

}